


















































































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';

import DDAConciliation from '@/domain/models/DDAConciliation';
import StatusType from '@/domain/enums/DDAConciliationStatusType';
import ActionType from '@/domain/enums/DDAConciliationActionType';
import OriginType from '@/domain/enums/DDAConciliationOriginType';

@Component({})
export default class DDAConciliationListAction extends Vue {
  @Prop({
    type: Object as () => DDAConciliation,
  }) readonly item!: DDAConciliation;

  @Emit()
  action(type: string) {
    return { type, item: this.item };
  }

  readonly actionType = ActionType;

  get status(): StatusType {
    return this.item.status;
  }

  get showMenu(): boolean {
    return (
      this.status !== StatusType.PENDING
      && this.status !== StatusType.CANCELED
      && this.status !== StatusType.PENDING_CONCILIATION_ERP
    );
  }

  get showResolve(): boolean {
    return (
      this.status === StatusType.DIVERGENCE
      || this.status === StatusType.ERP_WITH_BARCODE
    );
  }

  get showInvoice(): boolean {
    return (
      this.status === StatusType.DIVERGENCE
      || this.status === StatusType.NOT_FOUND_ERP
      || this.status === StatusType.ERP_WITH_BARCODE
      || this.status === StatusType.UNDONE
      || this.status === StatusType.WITHOUT_ACCOUNT_PAYABLE
    );
  }

  get showSearch(): boolean {
    return (
      this.status === StatusType.DIVERGENCE
      || this.status === StatusType.NOT_FOUND_ERP
      || this.status === StatusType.ERP_WITH_BARCODE
      || this.status === StatusType.UNDONE
      || this.status === StatusType.WITHOUT_ACCOUNT_PAYABLE
    );
  }

  get showSearchDDA(): boolean {
    return this.status === StatusType.NOT_FOUND_DDA;
  }

  get hasPermissionToGenerateAccountPayable(): number {
    return Number(this.$session.get('user_access-generate_dda_account_payable'));
  }

  get showRegisterBarcode(): boolean {
    return false;
    // return this.status === StatusType.NOT_FOUND_DDA;
  }

  get showUndoConciliation(): boolean {
    return (
      this.status === StatusType.CONCILIATED
      || this.status === StatusType.ERP_CONCILIATION_FAILURE
      || this.status === StatusType.DIVERGENCE
    );
  }

  get showJustUndoConciliation(): boolean {
    return (
      this.showUndoConciliation
      && !this.showResolve
      && !this.showInvoice
      && !this.showSearch
      && !this.showViewInvoice
    );
  }

  get showViewInvoice(): boolean {
    return this.item.movements
      .some((mov) => mov.origin === OriginType.INN);
  }

  get showRemoveDDA(): boolean {
    return (
      this.item.movements.length === 1
      && this.item.movements[0].origin === OriginType.DDA
    );
  }
}
